export const AppTopItem = () => import('../../components/AppTopItem.vue' /* webpackChunkName: "components/app-top-item" */).then(c => wrapFunctional(c.default || c))
export const CustomSwiper = () => import('../../components/CustomSwiper.vue' /* webpackChunkName: "components/custom-swiper" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const FullScreen = () => import('../../components/FullScreen.vue' /* webpackChunkName: "components/full-screen" */).then(c => wrapFunctional(c.default || c))
export const MerchNotify = () => import('../../components/MerchNotify.vue' /* webpackChunkName: "components/merch-notify" */).then(c => wrapFunctional(c.default || c))
export const MerchTopItem = () => import('../../components/MerchTopItem.vue' /* webpackChunkName: "components/merch-top-item" */).then(c => wrapFunctional(c.default || c))
export const PaintingList = () => import('../../components/PaintingList.vue' /* webpackChunkName: "components/painting-list" */).then(c => wrapFunctional(c.default || c))
export const ArtistGallery = () => import('../../components/artist/Gallery.vue' /* webpackChunkName: "components/artist-gallery" */).then(c => wrapFunctional(c.default || c))
export const ArtistManifest = () => import('../../components/artist/Manifest.vue' /* webpackChunkName: "components/artist-manifest" */).then(c => wrapFunctional(c.default || c))
export const ArtistToggleButton = () => import('../../components/artist/ToggleButton.vue' /* webpackChunkName: "components/artist-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const AmpAppTopItem = () => import('../../components/amp/AmpAppTopItem.vue' /* webpackChunkName: "components/amp-app-top-item" */).then(c => wrapFunctional(c.default || c))
export const AmpCustomSwiper = () => import('../../components/amp/AmpCustomSwiper.vue' /* webpackChunkName: "components/amp-custom-swiper" */).then(c => wrapFunctional(c.default || c))
export const BuyPainting = () => import('../../components/buy/BuyPainting.vue' /* webpackChunkName: "components/buy-painting" */).then(c => wrapFunctional(c.default || c))
export const BuyCompletionBuy = () => import('../../components/buy/CompletionBuy.vue' /* webpackChunkName: "components/buy-completion-buy" */).then(c => wrapFunctional(c.default || c))
export const BuyMakingPurchase = () => import('../../components/buy/MakingPurchase.vue' /* webpackChunkName: "components/buy-making-purchase" */).then(c => wrapFunctional(c.default || c))
export const ArtistList = () => import('../../components/artist-gallery/ArtistList.vue' /* webpackChunkName: "components/artist-list" */).then(c => wrapFunctional(c.default || c))
export const ArtistGalleryInputSearch = () => import('../../components/artist-gallery/InputSearch.vue' /* webpackChunkName: "components/artist-gallery-input-search" */).then(c => wrapFunctional(c.default || c))
export const IndexFullScreenImage = () => import('../../components/index/FullScreenImage.vue' /* webpackChunkName: "components/index-full-screen-image" */).then(c => wrapFunctional(c.default || c))
export const IndexPaintingRate = () => import('../../components/index/PaintingRate.vue' /* webpackChunkName: "components/index-painting-rate" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/LayoutFooter.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/LayoutHeader.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutLang = () => import('../../components/layout/LayoutLang.vue' /* webpackChunkName: "components/layout-lang" */).then(c => wrapFunctional(c.default || c))
export const LayoutMerch = () => import('../../components/layout/LayoutMerch.vue' /* webpackChunkName: "components/layout-merch" */).then(c => wrapFunctional(c.default || c))
export const LayoutSearch = () => import('../../components/layout/LayoutSearch.vue' /* webpackChunkName: "components/layout-search" */).then(c => wrapFunctional(c.default || c))
export const JoinForm = () => import('../../components/join/JoinForm.vue' /* webpackChunkName: "components/join-form" */).then(c => wrapFunctional(c.default || c))
export const JoinPreview = () => import('../../components/join/JoinPreview.vue' /* webpackChunkName: "components/join-preview" */).then(c => wrapFunctional(c.default || c))
export const JoinSuccess = () => import('../../components/join/JoinSuccess.vue' /* webpackChunkName: "components/join-success" */).then(c => wrapFunctional(c.default || c))
export const MerchToggleButton = () => import('../../components/merch/MerchToggleButton.vue' /* webpackChunkName: "components/merch-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/news-list/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const PopupsApplicationPopup = () => import('../../components/popups/ApplicationPopup.vue' /* webpackChunkName: "components/popups-application-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsIntroPopup = () => import('../../components/popups/IntroPopup.vue' /* webpackChunkName: "components/popups-intro-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsPromoCodePopup = () => import('../../components/popups/PromoCodePopup.vue' /* webpackChunkName: "components/popups-promo-code-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSynergyPopup = () => import('../../components/popups/SynergyPopup.vue' /* webpackChunkName: "components/popups-synergy-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsWebPushPopup = () => import('../../components/popups/WebPushPopup.vue' /* webpackChunkName: "components/popups-web-push-popup" */).then(c => wrapFunctional(c.default || c))
export const SaleInfo = () => import('../../components/sale/SaleInfo.vue' /* webpackChunkName: "components/sale-info" */).then(c => wrapFunctional(c.default || c))
export const AmpHeader = () => import('../../components/amp/layout/AmpHeader.vue' /* webpackChunkName: "components/amp-header" */).then(c => wrapFunctional(c.default || c))
export const AmpMenu = () => import('../../components/amp/layout/AmpMenu.vue' /* webpackChunkName: "components/amp-menu" */).then(c => wrapFunctional(c.default || c))
export const AmpNewsItem = () => import('../../components/amp/news/AmpNewsItem.vue' /* webpackChunkName: "components/amp-news-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenu = () => import('../../components/layout/menu/Menu.vue' /* webpackChunkName: "components/layout-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuBanner = () => import('../../components/layout/menu/MenuBanner.vue' /* webpackChunkName: "components/layout-menu-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuContent = () => import('../../components/layout/menu/MenuContent.vue' /* webpackChunkName: "components/layout-menu-content" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuToggleLang = () => import('../../components/layout/menu/ToggleLang.vue' /* webpackChunkName: "components/layout-menu-toggle-lang" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
